import React from "react";
import FileExtensionButton from "./FileExtensionButton";

function FileExtensionSquare({ extensionValue, onExtensionClick }) {
  return (
    <div className="file_extension_square">
      <FileExtensionButton
        extensionValue={extensionValue}
        onClick={() => onExtensionClick(extensionValue)}
      />
    </div>
  );
}

export default FileExtensionSquare;
