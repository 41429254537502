import React from "react";

function FileExtensionButton({ extensionValue, onClick }) {
  return (
    <button className="file_extension_button" onClick={onClick}>
      {extensionValue}
    </button>
  );
}

export default FileExtensionButton;
