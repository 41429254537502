import React, { useState } from 'react';
import useT from '../Traduction'
import linkedinIcon from '../../assets/images/icons/linkedin.svg'
import phoneCall from '../../assets/images/icons/phone-call.svg'
import emailIcon from '../../assets/images/icons/e-mail.svg'
import futurosoftLogoBlanc from '../../assets/images/Futurosoft_Logos_Blanc.png'
import dlSoftwareLogo from '../../assets/images/logo_dl_software.png'

function Footer() {
  const t = useT()
  const [showPhone, setShowPhone] = useState(false);

  const handleClick = () => {
    setShowPhone(!showPhone);
  }

  return (
    <footer className="footer_desktop footer_mobile d-flex justify-content-around mt-5">
      <div className="container row">
        <div className="col-md-4 order-2 order-md-1 d-flex justify-content-evenly justify-content-md-start flex-md-column  align-self-center">
          <div className="d-flex justify-content-md-start">
            <img className="image_footer" src={futurosoftLogoBlanc} alt="logo_futurosoft"/>
          </div>
          <p className="d-none d-md-inline d-flex justify-content-md-start align-items-center mb-0">8/10 rue du bois sauvage 91055 Evry Cedex</p>
        </div>

        <div className="col-md-4 order-1 order-md-2 column">
          <p className="titre d-flex justify-content-center m-1 m-md-2">{t("Contact_us")}</p>
          <div className="d-flex justify-content-evenly">
            <a href="mailto:support@futurosoft.fr"><img className="icone_svg svg_blanc" src={emailIcon} alt="mail"/></a>
            <a href="https://fr.linkedin.com/company/futurosoft" target="_blank" rel="noopener noreferrer"><img className="icone_svg svg_blanc" src={linkedinIcon} alt="linkedin"/></a>
            <div>
              {showPhone ?
                <span className="telephone_number" onClick={handleClick}>
                  02 28 25 08 00
                </span>
                :
                <img
                  className="icone_svg svg_blanc"
                  src={phoneCall}
                  alt="telephone"
                  onClick={handleClick}
                />
              }
            </div>
          </div>
        </div>

        <div className="col-4 d-none d-md-block order-3 align-self-center">
          <div className="d-flex justify-content-end">
            <img className="image_footer" src={dlSoftwareLogo} alt="logo_dl_software"/>
          </div>
          <p className="d-flex justify-content-end">{t("Futu_dl_description")}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
