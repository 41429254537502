import { useTranslation } from 'react-i18next';

const useT = () => {
  const { t } = useTranslation();
  let translatedValue;
  return (key) => {
    if (key.toUpperCase() === key) {
      translatedValue = t(key.toLowerCase()).toUpperCase();
    } else if (key.toLowerCase() === key) {
      translatedValue = t(key.toLowerCase());
    } else if (capitalize(key) === key) {
      translatedValue = capitalize(t(key.toLowerCase()));
    } else {
      translatedValue = t(key.toLowerCase());
    }
    return translatedValue;
  };
};
export default useT;

function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}