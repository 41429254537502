import React from 'react';
import { Link } from 'react-router-dom'


function ButtonLink( { link, className, linkText } ) {
    return (
        <div>
            <Link to={link} className={className}>{linkText}</Link>
        </div>
    )
  }  

export default ButtonLink