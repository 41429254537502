import { Auth } from 'aws-amplify';

async function refreshSession() {
  try {
    await Auth.currentSession();
  } catch (error) {
        console.error(error);
        Auth.signOut();
  }
}

export default refreshSession;
