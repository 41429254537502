import React, { useState } from "react";
import useT  from '../../Traduction';

const FileInformations = ({ fileSeparator, setFileSeparator, hasHeader, setHasHeader, setFileInformationsCompleted, unitPrice, setUnitPrice, fixedFieldSize, setFixedFieldSize, extension, formatName, setCountry, setLanguage, languageValue, countryValue, skipRows, setSkipRows, setFileEncoding, fileEncoding }) => {
  const t = useT();
  const [errorMessage, setErrorMessage] = useState("");
  const handleButtonClick = (event) => {
    event.preventDefault();
    if (fixedFieldSize === true) {
      if (unitPrice !== "") {
        setFileInformationsCompleted(true)
      }
      else {
        setErrorMessage(t("Missing_unit_price_information"))
      }
      
    } else {
      setFileInformationsCompleted(true)
    }
  };
  return (
    <form className="form-control text-center shadow p-3 mb-5 bg-body rounded">
      <p>{t("The_format_name_is")} : <span className="fw-bold">{formatName}</span></p>
      <div className="row mb-1">
        <div className="col-md-6 mb-3 mt-3 text-center">
          <label className="my-1 fw-bold">{t("Header")} :</label>
          <div>
              <input
                type="radio"
                value="true"
                checked={hasHeader === true}
                onChange={() => setHasHeader(true)}
              />
              <span className="mx-2">{t("Yes")}</span>
          </div>
          <div>
              <input
                type="radio"
                value="false"
                checked={hasHeader === false}
                onChange={() => setHasHeader(false)}
              />
              <span className="mx-2">{t("No")}</span>
          </div>
        </div>
        <div className="col-md-6 mb-3 mt-2 text-center">
            <label className="my-1 fw-bold">{t("Country")} :</label>
            <select
              className="form-control"
              value={countryValue}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option className="text-center" value="BE">{t("Belgium")}</option>
              <option className="text-center" value="CH">{t("Switzerland")}</option>
              <option className="text-center" value="DE">{t("Germany")}</option>
              <option className="text-center" value="EN">{t("England")}</option>
              <option className="text-center" value="ES">{t("Spain")}</option>
              <option className="text-center" value="FR">{t("France")}</option>
              <option className="text-center" value="IT">{t("Italy")}</option>
              <option className="text-center" value="LU">{t("Luxembourg")}</option>
              <option className="text-center" value="NL">{t("Netherlands")}</option>
            </select>
          </div>
        <div className="col-md-6 mb-3 mt-2 text-center">
          <label className="my-1 fw-bold">{t("Language")} :</label>
          <select
            className="form-control"
            value={languageValue}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <option className="text-center" value="DE">{t("German")}</option>
            <option className="text-center" value="EN">{t("English")}</option>
            <option className="text-center" value="ES">{t("Spanish")}</option>
            <option className="text-center" value="FR">{t("French")}</option>
            <option className="text-center" value="IT">{t("Italian")}</option>
            <option className="text-center" value="LU">{t("Luxembourgish")}</option>
            <option className="text-center" value="NL">{t("Dutch")}</option>
          </select>
        </div>
        <div className="col-md-6 mb-3 mt-2 text-center">
          <label className="my-1 fw-bold">{t("Number_of_lines_to_skip")} :</label>
            <input
              type="number"
              className="form-control"
              value={skipRows}
              min="0"
              onChange={(e) => {
                const newSkipValue = Number(e.target.value);
                setSkipRows(newSkipValue);
              }}
              placeholder="Select a number of rows to skip"
            />
        </div>
        {extension === "CSV" && (
          <>
            <div className="col-md-6 mb-3 text-center">
              <label className="my-1 fw-bold">{t("Encoding")} :</label>
                <select
                  className="form-control"
                  value={fileEncoding}
                  onChange={(e) => setFileEncoding(e.target.value)}
                >
                  <option className="text-center" value="">{t("Select_an_encoding")}</option>
                  <option className="text-center" value="UTF-8">UTF-8</option>
                  <option className="text-center" value="ISO-8859-1">ISO-8859-1 (Latin-1)</option>
                  <option className="text-center" value="UTF-16">UTF-16</option>
                  <option className="text-center" value="UTF-32">UTF-32</option>
                  <option className="text-center" value="ASCII">ASCII</option>
                  <option className="text-center" value="ISO-8859-15">ISO-8859-15 (Latin-9)</option>
                  <option className="text-center" value="Windows-1252">Windows-1252</option>
                </select>
            </div>
            <div className="col-md-6 mb-3 text-center">
              <label className="my-1 fw-bold">{t("Delimiter")} :</label>
                <select
                  className="form-control"
                  value={fileSeparator}
                  onChange={(e) => setFileSeparator(e.target.value)}
                >
                  <option className="text-center" value="">{t("Select_a_delimiter")}</option>
                  <option className="text-center" value=";">{t("Semicolon")}</option>
                  <option className="text-center" value=",">{t("Comma")}</option>
                  <option className="text-center" value="/t">{t("Tab")}</option>
                  <option className="text-center" value="|">Pipe</option>
                  <option className="text-center" value=" ">{t("Space")}</option>
                  <option className="text-center" value=":">{t("Colon")}</option>
                </select>
            </div>
          </>
        )}
        {extension === "TXT" && (
          <div className="col-md-6 mb-3 mt-3 text-center">
            <label className="my-1 fw-bold">{t("Fixed_field_size")} :</label>
            <div>
                <input
                  type="radio"
                  value="true"
                  checked={fixedFieldSize === true}
                  onChange={() => setFixedFieldSize(true)}
                />
                <span className="mx-2">{t("Yes")}</span>
            </div>
            <div>
                <input
                  type="radio"
                  value="false"
                  checked={fixedFieldSize === false}
                  onChange={() => setFixedFieldSize(false)}
                />
                <span className="mx-2">{t("No")}</span>
            </div>
          </div>
        )}
        {fixedFieldSize && (
          <div className="col-md-6 mb-3 mt-2 text-center">
            <label className="my-1 fw-bold">{t("Unit_price")} :</label>
            <select
              className="form-control"
              value={unitPrice}
              onChange={(e) => setUnitPrice(e.target.value)}
            >
              <option className="text-center" disabled value="">{t("Select_unit_price")}</option>
              <option className="text-center" value="euros">{t("Price_without_comma")}</option>
              <option className="text-center" value="centimes">{t("Comma_price")}</option>
            </select>
          </div>
        )}
        {errorMessage && <div className="d-flex justify-content-center"><div className=" alert alert-warning mt-4 fw-bold center">{errorMessage}</div></div>}
        <div className="d-flex justify-content-center my-2">
          <button type="submit" className="btn mt-3" onClick={handleButtonClick}>{t("Validate")}</button>
        </div>
      </div>
    </form>
  );
}

export default FileInformations;
