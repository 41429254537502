import { Auth } from 'aws-amplify';

async function getDataProject() {
  try {
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
    const userAttribute = currentAuthenticatedUser.attributes
    const keyPrefix = currentAuthenticatedUser.keyPrefix
    const tokenId = currentAuthenticatedUser.signInUserSession.idToken
    const cognitoToken = tokenId.jwtToken
    let dataProject = {};
    if (cognitoToken) {
      let environment = process.env.REACT_APP_ENVIRONMENT;
      if (environment === 'production') {
        environment = 'g8';
      }
      const project = process.env.REACT_APP_PROJECT;
      const startUrl = `https://api.${project}.${environment}.gestimag.com`;
      
      return dataProject = {
        environment: environment,
        project: project,
        keyPrefix: keyPrefix,
        userAttribute: userAttribute,
        cognitoToken: cognitoToken,
        startUrl: startUrl
      };
    } else {
      return dataProject
    }
  } catch (error) {
    const notAuthenticated = "No current user"
    if (error === notAuthenticated) {
      console.log("The user is not authenticated")
    } else {
      console.log(error)
    }
  }
}

export default getDataProject