async function CallListSuppliers(dataProject) {
    let url = `https://api.datacatalog.${dataProject.environment}.gestimag.com/list-suppliers?paginate=true`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
        "Content-Type": "application/json",
      },
    });
    let dataSuppliers;
    
    try {
      const data = await response.json();
      dataSuppliers = data.result.suppliers;
    } catch (error) {
      return "Unexpected_error_when_retrieving_suppliers";
    }
    if (!response.ok) {
        if (response.status === 500) {
            return "Unexpected_error_when_retrieving_suppliers"
        } else if (response.status === 0) {
            return "Connection_error";
        } else if (response.status === 401) {
            console.log("Unauthorized");
            return "Unauthorized";
        } else if (response.status === 404) {
          return "No_supplier_found";
        } else {
            return "Unexpected_error_when_retrieving_suppliers";
        }
    } else {
      if (dataSuppliers) {
        const supplierName = [];
        for (const supplierObject of dataSuppliers) {
          supplierName.push(supplierObject.supplier);
        }
        return supplierName
      } else { 
        return "No_supplier_found";
      }
      
    }
  }
  
  export default CallListSuppliers;