import React, { useState } from "react";
import useT  from '../../Traduction';
import ValueOverview from "./OverViewComposants/ValueOverview";
import FieldMappingOverview from "./OverViewComposants/FieldMappingOverview";
import FileOutputOverview from "./OverViewComposants/FileOutputOverview";
import { Auth } from 'aws-amplify';

function OverviewFormat({ setFormatName, formatName, extension, hasHeader, fileSeparator, fixedFieldSize, lineEnding, fileEncoding, quoteCharacter, unitPrice, fieldMapping, fileOutput, setFileOuput, supplier, countryValue, languageValue, dataProject, skipRows, lengthUnit, heightUnit, volumeUnit, weightUnit, widthUnit }) {
    const t = useT();
    const [completeMessage, setCompleteMessage]  = useState("");
    const [errorMessage, setErrorMessage]  = useState("");
    const [inProgressMessage, setInProgressMessage] = useState("");
    const [nameAlreadyExist, setNameAlreadyExist]  = useState(false);
    const [catalogAlreadyExist, setCatalogAlreadyExist]  = useState(false);
    const formatData = {
        format: {
            name: "",
            supplier: supplier,
            has_headers: hasHeader,
            encoding: fileEncoding,
            file_type: extension.toLowerCase(),
            fixed_field_size: fixedFieldSize,
            country: countryValue,
            language: languageValue,
            separator: fileSeparator,
            quoting_char: quoteCharacter,
            unit_price: unitPrice,
            end_of_line_chars: lineEnding,
            fields_mapping: fieldMapping,
            file_output: {},
            estimated_order: 0,
            TTL: null,
            skip_rows: skipRows,
            unit_length: lengthUnit,
            unit_height: heightUnit,
            unit_volume: volumeUnit, 
            unit_weight: weightUnit, 
            unit_width: widthUnit
        },
    };
    const handleCreateFormat = async (e) => {
        e.preventDefault();
        setNameAlreadyExist(false)
        setCatalogAlreadyExist(false)
        setCompleteMessage("");
        setErrorMessage("");
        setInProgressMessage("")
        formatData.format.name = formatName.toUpperCase()
        formatData.format.file_output = fileOutput
        const confirmationAlert = window.confirm(`${t("Do_you_want")} ${t("create")} ${t("the_male")} ${t("format")} ${formatData.format.name} ${t("of_type")} ${formatData.format.file_type.toUpperCase()} ${t("for_the_supplier")} ${formatData.format.supplier} ?`);
        if (confirmationAlert) {
            setNameAlreadyExist(false)
            setInProgressMessage(t("Format_creation_in_progress"))
            await fetchCreateFormat(formatData, formatName, extension, setCompleteMessage, setErrorMessage, setNameAlreadyExist, setCatalogAlreadyExist, dataProject, t, setInProgressMessage);
        }
      }
    
    return (
        <form className="shadow p-3 mb-5 bg-body rounded" onSubmit={handleCreateFormat}>
            <ValueOverview
                setFormatName={setFormatName}
                formatData={formatData}   
                nameAlreadyExist={nameAlreadyExist}
                formatName={formatName}
            />
            <FieldMappingOverview
                formatData={formatData}  
            />
            <FileOutputOverview
                catalogAlreadyExist={catalogAlreadyExist}
                fileOutput={fileOutput}
                setFileOutput={setFileOuput}
            />
            <div className="text-center mt-3">
                <button type="submit" className="btn mt-3">{t("Validate")}</button>
            </div>
            {completeMessage && <div className="d-flex justify-content-center"><div className=" alert alert-success mt-4 fw-bold center">{completeMessage}</div></div>}
            {errorMessage && <div className="d-flex justify-content-center"><div className=" alert alert-warning mt-4 fw-bold center">{errorMessage}</div></div>}
            {inProgressMessage && <div className="d-flex justify-content-center"><div className=" alert alert-primary mt-4 fw-bold center">{inProgressMessage}</div></div>}
        </form>
    );
}

function replaceEmptyWithNull(data, keys) {
    keys.forEach(key => {
      if (data[key] === "") {
        data[key] = null;
      }
    });
  }

async function fetchCreateFormat(formatData, formatName, extension, setCompleteMessage, setErrorMessage, setNameAlreadyExist, setCatalogAlreadyExist, dataProject, t, setInProgressMessage) {
    const keysToCheck = ['unit_length', 'unit_height', 'unit_volume', 'unit_weight', 'unit_width'];
    replaceEmptyWithNull(formatData, keysToCheck);
    const contentBodyJson = JSON.stringify(formatData);
    let url = `${dataProject.startUrl}/create-format`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
        "Content-Type": "application/json",
      },
      body: contentBodyJson,
    });
    let dataResultMessage;
    let dataKeys;
    
    try {
      const data = await response.json();
      dataResultMessage = data.result.message;
      if (data.result.keys) {
        dataKeys = data.result.keys
      }

    } catch (error) {
      setErrorMessage(t("Unexpected_error"));
    }
    setInProgressMessage("")
    if (!response.ok) {
        if (response.status === 400) {
            if (dataResultMessage === "Exception during event parsing") {
                setErrorMessage(t("Error_in_json_format"));
            } else if (dataResultMessage === "The format name already exists for this file type") {
                setNameAlreadyExist(true)
                setErrorMessage(`${t("Error_format")} ${formatName} ${t("already_exist_for_the_type")} ${extension}.`);
            } else if (dataResultMessage === "At least one catalog name already exists") {
                setCatalogAlreadyExist(true);
                const catalogNames = dataKeys.map((item) => item.catalog_name);
                if (catalogNames.length > 1) {
                    setErrorMessage(`${t("Many_catalogs_names")} ${t("already_exist_for_the_supplier")} ${formatData.format.supplier}: ${catalogNames.join(', ')}`);
                } else {
                    setErrorMessage(`${t("One_catalog_name")} ${catalogNames[0]} ${t("already_exist_for_the_supplier")} ${formatData.format.supplier}.`);
                }
            } else if (dataResultMessage === "Attention, you have multiple catalogs with the same name") {
                setCatalogAlreadyExist(true);
                setErrorMessage(t("Attention_you_have_multiple_catalogs_with_the_same_name"));
            }
        } else if (response.status === 500) {
            setErrorMessage(t("Unexpected_error"));
        } else if (response.status === 0) {
            setErrorMessage(`${t("Download_failed_due_to_a_connection_problem")}.`);
        } else if (response.status === 401) {
            setErrorMessage(t("Unauthorized"));
            console.log(t("Unauthorized"));
            Auth.signOut()
        } else {
            setErrorMessage(t("Unexpected_error"));
        }
    } else {
        setCompleteMessage(t("The_format_has_been_successfully_created"));
    }
  }

export default OverviewFormat;
