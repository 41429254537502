import React, { useState } from "react";
import useT  from '../../Traduction';


function FormatSupplierName({ setFormatName, setSupplier, setFormatAndSupplierNameCompleted, supplierNameList }) {
  const t = useT();
  const [inputValueSupplierName, setInputValueSupplierName] = useState("");
  const [inputValueFormatName, setInputValueFormatName] = useState("");
  const handleInputChangeFormatName = (event) => {
    setInputValueFormatName(event.target.value.toUpperCase());
  };  
  
  const handleInputChangeSupplierName = (event) => {
    setInputValueSupplierName(event.target.value.toUpperCase());
  };

  const saveFormatAndSupplierName = (event) => {
    event.preventDefault();
    setSupplier(inputValueSupplierName);
    setFormatName(inputValueSupplierName + "_" + inputValueFormatName.toUpperCase());
    setFormatAndSupplierNameCompleted(true);
  };
  
  return (
    <div className="col-md-12 mb-3 mt-4">
      <form className="shadow p-3 mb-5 bg-body rounded text-center">
        <div className="mb-3"> 
          <label className="my-2 fw-bold">{t("Supplier_name")} :</label>
          <select
            className="form-control"
            value={inputValueSupplierName}
            onChange={handleInputChangeSupplierName}
          >
            <option className="text-center" disabled value="">{t("Select_supplier")}</option>
            {supplierNameList.map((supplierName, index) => (
              <option className="text-center" key={index} value={supplierName}>{supplierName}</option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label className="my-2 fw-bold">{t("Name")} :</label>
          <input
            className="form-control"
            type="text"
            placeholder={t("Format_name")}
            value={inputValueFormatName}
            onChange={handleInputChangeFormatName}
          />
        </div>
        <div className="d-flex justify-content-center">
        {inputValueFormatName.trim() !== "" && inputValueSupplierName.trim() !== "" && (
          <p><span className="fw-bold">{t("Format_name")} : </span>{inputValueSupplierName}_{inputValueFormatName}</p>
          )}
        </div>
        <div className="d-flex justify-content-center ">
          {inputValueFormatName.trim() !== "" && inputValueSupplierName.trim() !== "" && (
            <button type="submit" className="btn mt-3" onClick={saveFormatAndSupplierName}>
              {t("Validate")}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default FormatSupplierName;
