import React from 'react';
import Header from '../Header'
import useT  from '../Traduction'
import ButtonLink from '../ButtonLink';


function Error( { errorCode, errorText} ) {
    const t = useT();
    return (
        <div>
            <Header titleHeader={t("Reference_Catalog")} />
            <div className="container-fluid error-management">
                <div className="row justify-content-center mt-5">
                    <div className="col-md-8 col-lg-6 error-color">
                        <div className="text-center">
                            <h1 className="mb-3">{errorCode}</h1>
                            <h2 className="mb-3">{t('Oops')}</h2>
                            <p className="mb-5">{errorText}</p>
                            <ButtonLink link="/" 
                                linkText={t('Back_to_the_home_page')}
                                className="button-large"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )    
  }  

export default Error