import React from 'react'
import Authentification from '../Authentification';


function FormLogin() {
  return (
    <div className="container-fluid mt-5 d-flex justify-content-center">
      <div className="boxed">
        <Authentification />
      </div>
    </div>
  )
}  

export default FormLogin