import React, { useState, useEffect } from "react";
import FileExtensionSquare from "./CreateFormatComposants/FileExtensionSquare";
import FileRepository from "./CreateFormatComposants/FileRepository";
import LinesFromFileTable from "./CreateFormatComposants/LinesFromFileTable";
import FileInformations from "./CreateFormatComposants/FileInformations";
import ResetButton from "./CreateFormatComposants/ResetButton";
import FormatSupplierName from "./CreateFormatComposants/FormatSupplierName";
import MappingFields from "./CreateFormatComposants/MappingFields";
import MeasurementUnit from "./CreateFormatComposants/MeasurementUnit"
import FileOutput from "./CreateFormatComposants/FileOutput";
import OverviewFormat from "./CreateFormatComposants/OverviewFormat";
import useT from '../../components/Traduction'
import { Auth } from 'aws-amplify';
import CallListSuppliers from "../ListSuppliers/CallListSuppliers"

function CreateNewFormat({ userGroup, dataProject }) {
  const t= useT();
  const extensionsValues = ["CSV", "TXT", "XLS", "XLSX"];
  const [countryValue, setCountry] = useState("FR");
  const [languageValue, setLanguage] = useState("FR");
  const [extension, setExtension] = useState("");
  const inputFileRepositoryAcceptExtension = "." + extension.toLowerCase();
  const [fileName, setFileName] = useState("");
  const [lines, setLines] = useState([]);
  const [fileSeparator, setFileSeparator] = useState("");
  const [hasHeader, setHasHeader] = useState(true);
  const [fileInformationsCompleted, setFileInformationsCompleted] = useState(false);
  const [fileEncoding, setFileEncoding] = useState("");
  const [supplier, setSupplier] = useState("");
  const [lineEnding, setLineEnding] = useState("");
  const [quoteCharacter, setQuoteCharacter] = useState("");
  const [formatName, setFormatName] = useState("");
  const [formatAndSupplierNameCompleted, setFormatAndSupplierNameCompleted] = useState(false);
  const [unitPrice, setUnitPrice] = useState("");
  const [fixedFieldSize, setFixedFieldSize] = useState(false)
  const [fileConfirmed, setFileConfirmed] = useState(false)
  const [fieldMapping, setFieldMapping] = useState([])
  const [fieldMappingConfirmed, setFieldMappingConfirmed] = useState(false)
  const [fileOutput, setFileOutput] = useState([])
  const [fileOutputConfirmed, setFileOutputConfirmed] = useState(false)
  const [headersRows, setHeadersRows] = useState([]);
  const [fixedFieldSizeColumnObject, setFixedFieldSizeColumnObject] = useState([])
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [listSupplierError, setListSupplierError] = useState("");
  const [skipRows, setSkipRows] = useState(0);
  const [measurementUnitConfirmed, setMeasurementUnitConfirmed] = useState(false)
  const [measurementUnitFields, setMeasurementUnitFields] = useState([])
  const [heightUnit, setHeightUnit] = useState("")
  const [lengthUnit, setLengthUnit] = useState("")
  const [volumeUnit, setVolumeUnit] = useState("")
  const [weightUnit, setWeightUnit] = useState("")
  const [widthUnit, setWidthUnit] = useState("")

  useEffect(() => {
    const fetchListSupplierAsync = async () => {
      const response = await CallListSuppliers(dataProject);
      if (typeof(response) === "string") {
        setListSupplierError(t(response))
        if (response === "Unauthorized") {
          Auth.signOut()
        }
        return []
      } else if (Array.isArray(response)) {
        setSupplierNameList(response)
        return response;
      }
    }
    fetchListSupplierAsync().then(supplierList => {
      setSupplierNameList(supplierList);
    });
  }, [dataProject]);

  const handleExtensionClick = (selectedExtension) => {
    setExtension(selectedExtension);
  };
  
  if (listSupplierError) {
    return (
      <div className="container-fluid mt-5 d-flex justify-content-center">
        {listSupplierError && <div className="d-flex justify-content-center"><div className=" alert alert-warning mt-4 fw-bold center">{listSupplierError}</div></div>}
      </div>
    )
  } else {
    if (userGroup === "super-admin" || userGroup === "admin") {
      return (
        <div className="container-fluid mt-5 d-flex justify-content-center">
          <div className="boxed-create-format">
            <ResetButton setExtension={setExtension} setLines={setLines} setFileSeparator={setFileSeparator} setHasHeader={setHasHeader} setFileInformationsCompleted={setFileInformationsCompleted} setFormatName={setFormatName} setFormatAndSupplierNameCompleted={setFormatAndSupplierNameCompleted} setUnitPrice={setUnitPrice} setFileConfirmed={setFileConfirmed} setFixedFieldSize={setFixedFieldSize} setFieldMapping={setFieldMapping} setFileOutput={setFileOutput} setFieldMappingConfirmed={setFieldMappingConfirmed} setFileOutputConfirmed={setFileOutputConfirmed} setFileEncoding={setFileEncoding} setSupplier={setSupplier} setQuoteCharacter={setQuoteCharacter} setHeadersRows={setHeadersRows} setFixedFieldSizeColumnObject={setFixedFieldSizeColumnObject} setCountry={setCountry} setLanguage={setLanguage} setMeasurementUnitConfirmed={setMeasurementUnitConfirmed} setMeasurementUnitFields={setMeasurementUnitFields} setHeightUnit={setHeightUnit} setLengthUnit={setLengthUnit} setVolumeUnit={setVolumeUnit} setWeightUnit={setWeightUnit} setWidthUnit={setWidthUnit} />
            <div className="d-flex justify-content-center">
              {!formatAndSupplierNameCompleted && (
                <div>
                  <h2 className="mt-5">{t("Naming")}</h2>
                  <div className="mt-3 mx-5 ">
                    <FormatSupplierName 
                      setFormatName={setFormatName}
                      setSupplier={setSupplier}
                      setFormatAndSupplierNameCompleted={setFormatAndSupplierNameCompleted}
                      supplierNameList={supplierNameList}
                    />
                  </div>
                </div>
              )}
              {(formatAndSupplierNameCompleted && !extension) && (
                <div>
                  <h2 className="mt-5">{t("File_extension")}</h2>
                  <div className="mt-3 mx-5 extension_button">
                    {extensionsValues.map((extensionValue, index) => (
                      <FileExtensionSquare
                        key={index}
                        extensionValue={extensionValue}
                        onExtensionClick={handleExtensionClick}
                      />
                    ))}
                  </div>
                </div>
              )}
              {(formatAndSupplierNameCompleted && extension) && (
                <div>
                  {!fileInformationsCompleted && (
                    <div className="text-center">
                      <h2 className="mt-5">{t("File_informations")}</h2>
                      <FileInformations
                        fileSeparator={fileSeparator}
                        setFileSeparator={setFileSeparator}
                        hasHeader={hasHeader}
                        setHasHeader={setHasHeader}
                        setFileInformationsCompleted={setFileInformationsCompleted}
                        unitPrice={unitPrice}
                        setUnitPrice={setUnitPrice}
                        fixedFieldSize={fixedFieldSize}
                        setFixedFieldSize={setFixedFieldSize}
                        extension={extension}
                        formatName={formatName}
                        supplier={supplier}
                        setCountry={setCountry}
                        countryValue={countryValue}
                        setLanguage={setLanguage}
                        languageValue={languageValue}
                        skipRows={skipRows}
                        setSkipRows={setSkipRows}
                        setFileEncoding={setFileEncoding}
                        fileEncoding={fileEncoding}
                      />
                    </div>
                  )}
                  {fileInformationsCompleted && headersRows.length === 0 && lines.length === 0 && (
                    <div>
                      <h2 className="mt-5">{t("Choose_a_file_to_upload")}</h2>
                      <FileRepository
                        inputFileRepositoryAcceptExtension={inputFileRepositoryAcceptExtension}
                        setLines={setLines} 
                        setFileEncoding={setFileEncoding}
                        setLineEnding={setLineEnding} 
                        setQuoteCharacter={setQuoteCharacter} 
                        setFileName={setFileName}
                        extension={extension}
                        quoteCharacter={quoteCharacter}
                        fileSeparator={fileSeparator}
                        setHeadersRows={setHeadersRows} 
                        setFileSeparator={setFileSeparator}
                        hasHeader={hasHeader}
                        setHasHeader={setHasHeader}
                        fixedFieldSize={fixedFieldSize}
                        fixedFieldSizeColumnObject={fixedFieldSizeColumnObject}
                        setFixedFieldSizeColumnObject={setFixedFieldSizeColumnObject}
                        formatName={formatName}
                        supplier={supplier}
                        skipRows={skipRows}
                        setSkipRows={setSkipRows}
                        fileEncoding={fileEncoding}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {headersRows.length > 0 && lines.length > 0 && !fileConfirmed && (
              <div className="mt-3 text-center">
                <h2 className="mt-5 mb-3">{t("Preview_your_file")}</h2>
                <LinesFromFileTable 
                  lines={lines} 
                  fileSeparator={fileSeparator} 
                  hasHeader={hasHeader} 
                  setFileConfirmed={setFileConfirmed}
                  fileName={fileName}
                  headersRows={headersRows} 
                  formatName={formatName}
                  supplier={supplier}
                  skipRows={skipRows}
                  setSkipRows={setSkipRows}
                  />
              </div>
            )}
            {fileConfirmed && !fieldMappingConfirmed && (
              <div className="mt-3 text-center">
                <h2 className="mt-5">{t("File_mapping")}</h2>
                <MappingFields 
                  lines={lines} 
                  fileSeparator={fileSeparator} 
                  fieldMapping={fieldMapping} 
                  setFieldMapping={setFieldMapping}
                  fixedFieldSize={fixedFieldSize}
                  setFieldMappingConfirmed={setFieldMappingConfirmed}
                  headersRows={headersRows} 
                  formatName={formatName}
                  supplier={supplier}
                  fixedFieldSizeColumnObject={fixedFieldSizeColumnObject}
                  hasHeader={hasHeader}
                  skipRows={skipRows}
                  setSkipRows={setSkipRows}
                  setMeasurementUnitConfirmed={setMeasurementUnitConfirmed}
                  setMeasurementUnitFields={setMeasurementUnitFields}
                />
              </div>
            )}
            {fieldMappingConfirmed && measurementUnitFields && !measurementUnitConfirmed && (
              <div className="mt-3 text-center">
                <h2 className="mt-5">{t("Measurement_unit")}</h2>
                <MeasurementUnit 
                  fieldMapping={fieldMapping}
                  setMeasurementUnitConfirmed={setMeasurementUnitConfirmed}
                  measurementUnitFields={measurementUnitFields}
                  lengthUnit={lengthUnit} setLengthUnit={setLengthUnit}
                  heightUnit={heightUnit} setHeightUnit={setHeightUnit}
                  volumeUnit={volumeUnit} setVolumeUnit={setVolumeUnit}
                  weightUnit={weightUnit} setWeightUnit={setWeightUnit}
                  widthUnit={widthUnit} setWidthUnit={setWidthUnit}
                />
              </div>
            )}
            {fieldMappingConfirmed && measurementUnitConfirmed && !fileOutputConfirmed && (
              <div className="mt-3 text-center">
                <h2 className="mt-5">{t("Output_file")}</h2>
                <FileOutput 
                  fileOutput={fileOutput}
                  setFileOutput={setFileOutput}
                  setFileOutputConfirmed={setFileOutputConfirmed}
                  formatName={formatName}
                  supplier={supplier}
                />
              </div>
            )}
            {fileOutputConfirmed && (
              <div className="mt-3">
                <h2 className="mt-5 text-center mb-3">{t("Format_preview_and_validation")}</h2>
                <OverviewFormat 
                  setFormatName={setFormatName}
                  formatName={formatName} 
                  extension={extension}
                  hasHeader={hasHeader}
                  fileSeparator={fileSeparator}
                  fixedFieldSize={fixedFieldSize}
                  lineEnding={lineEnding}
                  fileEncoding={fileEncoding}
                  quoteCharacter={quoteCharacter}
                  unitPrice={unitPrice}
                  fieldMapping={fieldMapping}
                  fileOutput={fileOutput}
                  setFileOutput={setFileOutput}
                  supplier={supplier}
                  countryValue={countryValue}
                  languageValue={languageValue}
                  dataProject={dataProject}
                  skipRows={skipRows}
                  lengthUnit={lengthUnit}
                  heightUnit={heightUnit}
                  volumeUnit={volumeUnit}
                  weightUnit={weightUnit}
                  widthUnit={widthUnit}
                />
              </div>
            )}
          </div>
        </div>
      );  
    }
  }
}

export default CreateNewFormat;
