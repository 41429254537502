import { Auth } from 'aws-amplify';

async function CheckUserGroup() {
    try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userGroup = currentUser.signInUserSession.accessToken.payload['cognito:groups'];
        if (userGroup.includes('super-admin')) {
            if (userGroup.includes('admin')) {
                return 'super-admin';
            } else if (userGroup.includes('client')) {
                return 'super-admin';
            } else {
                return 'super-admin';
            }
        } else if (userGroup.includes('admin')) {
            if (userGroup.includes('client')) {
                return 'admin';
            } else {
                return 'admin';
            }
        } else if (userGroup.includes('client')) {
            return 'client';
        } else {
            return 'Aucun des rôles spécifiés n\'est présent';
        }
    } catch (error) {
      const disconnectedUser = "Disconnected user"
      return disconnectedUser;
    }
}
  
  export default CheckUserGroup;
