import React from "react";
import useT  from '../../Traduction';

const LinesFromFileTable = ({ lines, setFileConfirmed, fileName, headersRows, formatName }) => {
  const t = useT();
  const handleConfirmFile = () => {
    setFileConfirmed(true);
  };
  return (
    <div>
      <p>{t("The_format_name_is")} : <span className="fw-bold">{formatName}</span></p>
      <p>{t("You_have_downloaded_the_file")}: <span className="fw-bold">{fileName}</span></p>
      <div className="mt-3 table-responsive">
        <table className="table table-hover table-bordered text-center table-reference-custom">
          <thead>
            <tr>
              {headersRows.length > 0 &&
                headersRows.map((value, index) => (
                  <th key={index} className="center th_table_custom">{value}</th>
                ))
              }
            </tr>
          </thead>
          <tbody>
           {lines.length > 0 && 
              lines.map((line, linesIndex) => (
                <tr key={linesIndex}>
                  {line.map((value, index) => (
                    <td key={index} className="center">{value}</td>
                  ))}
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div className="mt-5 d-flex justify-content-center">
        <button type="submit" className="btn" onClick={handleConfirmFile}>{t("Confirm_file")}</button>
      </div>
    </div>
  );
}

export default LinesFromFileTable;
