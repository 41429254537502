import { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import { Auth } from 'aws-amplify';
import CallListSuppliers from "../ListSuppliers/CallListSuppliers"
import useT from '../Traduction'


const getLastPartAfterSlash = (fileName) => {
  const parts = fileName.split("/");
  return parts[parts.length - 1];
};

function FormSupplier({ userGroup, dataProject }) {
  const t = useT();
  const [errorMessage, setErrorMessage] = useState("");
  const [supplier, setSupplier] = useState("");
  const [catalogs, setCatalogs] = useState([]);
  const [isSelectVisible, setIsSelectVisible] = useState(false);
  const [loadingBtnIndex, setLoadingBtnIndex] = useState(null);
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [listSupplierError, setListSupplierError] = useState("");

  useEffect(() => {
    const fetchListSupplierAsync = async () => {
      const response = await CallListSuppliers(dataProject);
      if (typeof(response) === "string") {
        setListSupplierError(t(response))
        if (response === "Unauthorized") {
          Auth.signOut()
        }
        return []
      } else if (Array.isArray(response)) {
        setSupplierNameList(response)
        return response;
      }
    }
    fetchListSupplierAsync().then(supplierList => {
      setSupplierNameList(supplierList);
    });
  }, []);

  const handleInputSupplier = (e) => {
    setSupplier(e.target.value)
    setLoadingBtnIndex(null)
  };

  function convertBytes(bytes) {
    if (bytes < 1000) {
      return `${bytes} O`;
    } else if (bytes < 1024 * 1024) {
      const kiloBytes = bytes / 1024;
      return `${kiloBytes.toFixed(2)} Ko`;
    } else if (bytes < 1024 * 1024 * 1024) {
      const megaBytes = bytes / (1024 * 1024);
      return `${megaBytes.toFixed(2)} Mo`;
    } else {
      const gigaBytes = bytes / (1024 * 1024 * 1024);
      return `${gigaBytes.toFixed(2)} Go`;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const supplierLower = document.getElementById("supplier").value;
    const supplier = supplierLower.toUpperCase()

    if (supplier === "") {
      setErrorMessage(t("Please_enter_a_supplier") + ".");
      return;
    }
    else {
      setErrorMessage("");
    }
  };
  if (listSupplierError) {
    return (
      <div className="container-fluid mt-5 d-flex justify-content-center">
        {listSupplierError && <div className="d-flex justify-content-center"><div className=" alert alert-warning mt-4 fw-bold center">{listSupplierError}</div></div>}
      </div>
    )
  } else {

    return (
      <>
        <div className="container-fluid mt-5 d-flex justify-content-center">
          <div className="flex">
            <form onSubmit={handleSubmit} className="form-control text-center shadow p-3 mb-5 bg-body rounded form-style">
              <div className="row gx-5">
                <div className="col-md-12">
                  <label htmlFor="supplier" className="mb-3 fw-bold">{t('Supplier')} :</label>
                  <select
                    className="form-control mt-2 mb-3"
                    value={supplier}
                    onChange={handleInputSupplier}
                  >
                    <option className="text-center" disabled value="">{t("Select_supplier")}</option>
                    {supplierNameList.map((supplierName, index) => (
                      <option className="text-center" key={index} value={supplierName}>{supplierName}</option>
                    ))}
                  </select>
                  
                <button type="button" className="btn input-small button-catalog m-3" onClick={() => {
                  setIsSelectVisible(true);
                  FetchListCatalog(supplier, setCatalogs, setErrorMessage, setSupplier, t, dataProject, userGroup);
                }}>
                  {t('Search_for_catalogs')}
                </button>

                  {isSelectVisible && (
                    <div>
                      <div className="mt-3 d-flex justify-content-center">
                        {errorMessage ? (
                          <div className="alert alert-warning fw-bold">{errorMessage}</div>
                        ) : (
                          catalogs.length >= 1 ? (
                            <table className="table table-hover table-responsive table-bordered shadow">
                              <colgroup>
                                <col className="col-width-10" />
                                <col className="col-width-10" />
                                <col className="col-width-30" />
                                <col className="col-width-30" />
                              </colgroup>
                              <thead>
                                <tr>
                                  <th className="deposit-tracking-col-name text-nowrap">{t("Catalog_name")}</th>
                                  <th className="deposit-tracking-col-name text-nowrap">{t("Size")}</th>
                                  <th className="deposit-tracking-col-name text-nowrap">{t("Last_modified")}</th>
                                  <th className="deposit-tracking-col-name text-nowrap">{t("Select_calatog")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {catalogs.map((item, index) => {
                                  return (
                                    <tr key={index} id="item">
                                      <td className="align-middle text-nowrap">{item.key && getLastPartAfterSlash(item.key)}</td>
                                      <td className="align-middle text-nowrap">{convertBytes(item.size)}</td>
                                      <td className="align-middle text-nowrap">{item.lastModified}</td>
                                      <td>
                                        {loadingBtnIndex === index ? (
                                          <button className="btn">
                                            <div className="loader"></div>
                                          </button>

                                        ) : (
                                          <button type="submit" className="btn" onClick={() => {
                                            setLoadingBtnIndex(index);
                                            DownloadCatalog(item.key, setErrorMessage, t, dataProject, setLoadingBtnIndex);
                                          }}>{t("Upload")}</button>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <div className="loader-container">
                              <div className="loader"></div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

async function FetchListCatalog(supplier, setCatalogs, setErrorMessage, setSupplier, t, dataProject, userGroup) {
  if (userGroup === "super-admin" || userGroup === "admin" || userGroup === "client") {
    if (supplier === "") {
      setErrorMessage(t("Please_enter_a_supplier"));
      return;
    }
    else {
      setErrorMessage("");
    }
    let url = `${dataProject.startUrl}/list-catalogs?supplier=${supplier}`;
    setCatalogs([]);

    try {
      const response = await fetch(url, {
        headers: {
          "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
        },
        method: 'GET'
      })
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 401) {
          setErrorMessage(`${t("Denied_access")}, ${t("Denied_access_message")}`);
          
        } else if (response.status === 404) {
          setErrorMessage(`${t("The_male")} ${t("supplier")} ${supplier} ${t("does_not_exist")}.`);
        } else {
          setErrorMessage(t("Unexpected_error"));
        }
        return;
      }

      const allCatalogs = data.result.catalogs;
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const formattedCatalogs = allCatalogs.map(catalog => ({
        ...catalog,
        lastModified: new Date(catalog.lastModified).toLocaleString('fr-FR', { timeZone: userTimezone }),
      }));

      setCatalogs(formattedCatalogs);
      setSupplier(supplier);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(t("Unexpected_error"));
    }
  } else {
    setErrorMessage(t("Required_rights_to_perform_this_action"))
  }
}


async function DownloadCatalog(key, setErrorMessage, t, dataProject, setLoadingBtnIndex) {
  try {
    let url = `${dataProject.startUrl}/read-supplier?key=${key}`;
    const response = await fetch(url, {
      headers: {
        "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
      },
      method: 'GET'
    })
    let dataResult;
    const data = await response.json();
        dataResult = data.result.file_url;
    if (!response.ok) {
      if (response.status === 404) {
        setErrorMessage(`${t("The_male")} ${t("file")} ${getLastPartAfterSlash(key)} ${t("does_not_exist")}.`);
      }
      else if (response.status === 0) {
        setErrorMessage(`${t("Download_failed_due_to_a_connection_problem")}.`);
      }
      else if (response.status === 401) {
        setErrorMessage(t("Unauthorized"));
        console.log(t("Unauthorized"));
        Auth.signOut()
      }
      else if (response.status === 400) {
        setErrorMessage(`${t("Missing_query_parameter")} , ${t("Catalog_name")} ${t("cannot_be_empty")}`);
      }
      else {
        setErrorMessage(t("Unexpected_error"));
      }
    }

    await FetchPresignedUrl(dataResult);

    setErrorMessage("");
    setLoadingBtnIndex(null)
  } catch (error) {
    setErrorMessage(t("Unexpected_error"));
  }

  async function FetchPresignedUrl(fileUrl) {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        console.error("Error response:", response.status, response.statusText);
        setErrorMessage(`Failed to fetch: ${response.statusText}`);
      }
      const blob = await response.blob();

      if (!blob.type || !blob.size) {
        setErrorMessage(`${t("The_male")} ${t("file")} ${getLastPartAfterSlash(key)} ${t("is_empty_or_corrupted")}.`);
      }

      saveAs(blob, getLastPartAfterSlash(key));

    } catch (error) {
      setErrorMessage("Erreur lors de la lecture du lien présigné", error);
    }
  }
}

export default FormSupplier;