import React, { useState, useEffect } from "react";
import useT  from '../../Traduction';
import { fields as initialFields } from '../../../datas/fields';
import { TbTrashXFilled } from "react-icons/tb";
import { Button } from "react-bootstrap";

const MappingFields = ({ lines, fieldMapping, setFieldMapping, fixedFieldSize, setFieldMappingConfirmed, headersRows, formatName, fixedFieldSizeColumnObject, hasHeader, setMeasurementUnitConfirmed, setMeasurementUnitFields }) => {
    const t = useT();
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedFields, setSelectedFields] = useState([]);
    // eslint-disable-next-line 
    const [fieldMappingObject, setFieldMappingObject] = useState({
        "output_field": "",
        "column_type" : "",
        "is_required": true,
        "start_of_length": 0,
        "end_of_length": 0,
    });
    const measurementUnits = ["height", "length", "volume", "weight", "width"];
    const [selectedHeadersRows, setSelectedHeadersRows] = useState(headersRows);
    if (Object.keys(fixedFieldSizeColumnObject).length > 0) {
        Object.entries(fixedFieldSizeColumnObject).forEach(([indexObj, obj]) => {
            const end = obj.start + obj.width;
            fixedFieldSizeColumnObject[indexObj] = { ...obj, end };
        });
    }

    const handleColumnChange = (key, subkey, value) => {
        setFieldMapping((prevFieldMapping) => {
            if (!prevFieldMapping[key]) {
                prevFieldMapping[key] = { ...fieldMappingObject };
            }
            prevFieldMapping[key][subkey] = value;
            return { ...prevFieldMapping };
        });
    };   

    const handleRemoveFieldMapping = (key) => { 
        setFieldMapping((prevFieldMapping) => {
            if (prevFieldMapping[key]) {
                const removedField = prevFieldMapping[key].output_field;
                setSelectedFields(prevSelectedFields => prevSelectedFields.filter(field => field !== removedField));
                delete prevFieldMapping[key];
            }
            return { ...prevFieldMapping };
        });
    };

    const handleConfirmMapping = () => {
        if (Object.keys(fieldMapping).length === 0) {
            setErrorMessage(t("The_file_has_not_been_mapped_please_select_at_least_one_mapping"));
        } else {
            let hasReference = false;
            let hasReference1 = false;
            let notEmptyEndOfLength = true;
    
            if (fixedFieldSize) {
                for (const key in fieldMapping) {
                    const element = fieldMapping[key];
                    if (element.end_of_length === 0) {
                        notEmptyEndOfLength = false;
                        break;
                    }
                }
            }
    
            for (const key in fieldMapping) {
                const element = fieldMapping[key];
                if (element.output_field === "reference_in_db") {
                    fieldMapping[key].output_field = "reference"
                    hasReference = true;
                } else if (element.output_field === "reference1") {
                    hasReference1 = true;
                }
            }
    
            if ((hasReference || hasReference1) && !notEmptyEndOfLength) {
                setErrorMessage(t("An_end_value_is_equal_to_zero"));
            } else if (!hasReference && !hasReference1 && !notEmptyEndOfLength) {
                setErrorMessage(`${t("You_must_import_the_output_field_Reference")} ${t("An_end_value_is_equal_to_zero")}.`);
            } else if (!hasReference && !hasReference1) {
                setErrorMessage(t("You_must_import_the_output_field_Reference"));
            } else {
                let unitFields = Object.values(fieldMapping).filter(field => measurementUnits.includes(field.output_field)).map(field => field.output_field)
                if (unitFields.length > 0) {
                    setMeasurementUnitFields(unitFields);
                }
                else {
                    setMeasurementUnitConfirmed(true);
                }
                setFieldMappingConfirmed(true);
                setErrorMessage("");
            }
        }
    };
    
    const handleCreateFieldMapping = (key, outputField, value, oldestValue) => {
        if (!hasHeader) {
            key = parseFloat(key);
        }
        let fixedFieldSizeColumnObjectIndex;
        if (fixedFieldSize) {
            for (const index in fixedFieldSizeColumnObject) {
                if (fixedFieldSizeColumnObject.hasOwnProperty(index)) {
                    if (typeof(key) === "number") {
                        if (fixedFieldSizeColumnObject[index].name === key + 1) {
                            fixedFieldSizeColumnObjectIndex = index;
                            break;
                        }
                    } else {
                        if (fixedFieldSizeColumnObject[index].name === key) {
                            fixedFieldSizeColumnObjectIndex = index;
                            break;
                        }
                    }
                }
            }
        }
        setSelectedFields(prevSelectedFields => prevSelectedFields.filter(field => field !== oldestValue));
        setFieldMapping((prevFieldMapping) => {
            const selectedDbValue = initialFields.find(field => field.in_db === value);
            const newFieldMapping = {
                ...fieldMappingObject,
                [outputField]: value,
                column_type: selectedDbValue.type,
                start_of_length: fixedFieldSizeColumnObject[fixedFieldSizeColumnObjectIndex]?.start ?? 0,
                end_of_length: fixedFieldSizeColumnObject[fixedFieldSizeColumnObjectIndex]?.end ?? 0
            };
            setSelectedFields(prevSelectedFields => [...prevSelectedFields, value]);
            return {
                ...prevFieldMapping,
                [key]: newFieldMapping,
            };
        });
    };

    const handleMatchingSelectValue = (headersRowsValue, sortedFields, output_field) => {
        let returnValue = output_field;
        if (returnValue === "") {
            outerLoop:
            for (const index in sortedFields) {
                for (const element in sortedFields[index].matching) {
                    const headersRowsValueWithoutAccent = headersRowsValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    const headersRowsValueCondition = headersRowsValueWithoutAccent.toLowerCase().replace(/[',;:/-]+/g, '');
                
                    if (headersRowsValueCondition === sortedFields[index].matching[element]) {
                        returnValue = sortedFields[index].in_db;            
                        break outerLoop;
                    }
                }
            }
        }
        return returnValue;
    };

    const sortedFields = [...initialFields]
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter(field => !field.name.endsWith("*"))
    .map(field => ({
        ...field,
        name: field.name.endsWith("*") ? field.name.slice(0, -1) : field.name
    }));

    
    useEffect(() => {
        const newHeadersRows = [];
        for (const headerRow of headersRows) {
            const defaultValue = fieldMapping[headerRow]?.output_field || "";
            const resultMatch = handleMatchingSelectValue(headerRow, sortedFields, defaultValue);
            if (resultMatch) {
                handleCreateFieldMapping(headerRow, "output_field", resultMatch, defaultValue);
                newHeadersRows.push(resultMatch);
            }
            else {
                newHeadersRows.push("");
            }
        }
        setSelectedHeadersRows(newHeadersRows);
        // eslint-disable-next-line
    }, []);
    
    return (
        <div className="container-fluid">
            <p>{t("The_format_name_is")} : <span className="fw-bold">{formatName}</span></p>
            <p className="">{t("Please_select_the_fields_corresponding_to_your_file_headers")} <br />
                {t("By_default_a_header_is_not_imported")} <br />
                {t("If_several_headers_make_up_the_output_fields_Reference_and_Designation")} <br />
                {t("Please_use_the_output_fields_Reference1_Reference2_Reference3_to_compose_the_Reference")} <br />
                {t("Please_use_the_output_fields_Designation1_Designation2_Designation3_to_compose_the_Designation")} <br />
                <span className="fw-bold">{t("You_must_import_the_output_field_Reference")}</span>
            </p>
            <div className="mt-3 table-responsive">
                <table className="table table-hover text-center table-mapping-file-custom">
                    <colgroup>
                        <col className="col-width-10" />
                        <col className="col-width-30" />
                        <col className="col-width-15" />
                        {fixedFieldSize === true && (
                            <>
                                <col className="col-width-10" />
                                <col className="col-width-10" />
                            </>
                        )}
                        <col className="col-width-5" />
                    </colgroup>
                    <thead className="thead-mapping-file-custom">
                        <tr>
                            <th className="tr-mapping-file-custom">{t("Header")}</th>
                            <th className="tr-mapping-file-custom">{t("Examples")}</th>
                            <th className="tr-mapping-file-custom">{t("Output_field")}</th>
                            {fixedFieldSize === true && (
                                <>
                                    <th className="tr-mapping-file-custom">{t("start_of_length")}</th>
                                    <th className="tr-mapping-file-custom">{t("end_of_length")}</th>
                                </>
                            )}
                            <th className="tr-mapping-file-custom">{t("Action")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {headersRows.map((headersRowsValue, headersIndex) => (
                            <tr key={headersIndex}>
                                <td className="headers-table">{headersRowsValue}</td>
                                <td>
                                    <div>
                                        <span>
                                            {lines[0] && lines[0][headersIndex] ? 
                                                (lines[0][headersIndex].length > 20 ? lines[0][headersIndex].slice(0, 30) + '...' : lines[0][headersIndex]) : ''}
                                            <br />
                                            {lines[1] && lines[1][headersIndex] ? 
                                                (lines[1][headersIndex].length > 20 ? lines[1][headersIndex].slice(0, 30) + '...' : lines[1][headersIndex]) : ''}
                                            <br />
                                            {lines[2] && lines[2][headersIndex] ? 
                                                (lines[2][headersIndex].length > 20 ? lines[2][headersIndex].slice(0, 30) + '...' : lines[2][headersIndex]) : ''}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <select
                                        className="form-control form-control-custom"
                                        onChange={(e) => handleCreateFieldMapping(headersRowsValue, "output_field", e.target.value, fieldMapping[headersRowsValue]?.output_field || "")}
                                        value={fieldMapping[headersRowsValue]?.output_field || selectedHeadersRows[headersIndex] || ""}
                                    >
                                        <option className="text-center" disabled value="">{t("Do_not_import")}</option>
                                        {sortedFields.map((element, optionIndex) => (
                                            <option
                                                key={optionIndex}
                                                className="text-center"
                                                value={element.in_db}
                                                disabled={selectedFields.includes(element.in_db)}
                                            >
                                                {t(element.name)}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                {fieldMapping[headersRowsValue] ? (
                                    <>
                                        {fixedFieldSize === true && (
                                            <>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder={t("start_of_length")}
                                                        onChange={(e) => handleColumnChange(headersRowsValue, "start_of_length", e.target.value)}
                                                        value={fieldMapping[headersRowsValue]?.start_of_length || 0}
                                                        disabled
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder={t("end_of_length")}
                                                        onChange={(e) => handleColumnChange(headersRowsValue, "end_of_length", e.target.value)}
                                                        value={fieldMapping[headersRowsValue]?.end_of_length || 0}
                                                        disabled
                                                    />
                                                </td>
                                            </>
                                        )}
                                        <td>
                                            <Button 
                                                className="btn-delete btn-delete-custom-mapping-file"
                                                onClick={() => handleRemoveFieldMapping(headersRowsValue)}
                                            >
                                                <TbTrashXFilled size={20} />
                                            </Button>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        {fixedFieldSize === true && <><td>X</td><td>X</td></>}
                                        <td>X</td>
                                    </>
                                )}

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {errorMessage && <div className="d-flex justify-content-center"><div className=" alert alert-warning mt-4 fw-bold center">{errorMessage}</div></div>}
            <div className="mt-5 d-flex justify-content-center">
                <button type="submit" className="btn" onClick={() => handleConfirmMapping()}>
                    {t("Confirm_mapping")}
                </button>
            </div>
        </div>
    );
}

export default MappingFields;
