import Header from '../../components/Header';
import Title from '../../components/Title';
import ButtonLink from '../../components/ButtonLink';
import useT from '../../components/Traduction'
import Footer from '../../components/Footer';
import GetFormFormat from '../../components/GetFormat/GetFormat';

function GetFormat({ userGroup, dataProject }) {
  const t = useT();

  return (
    <>
      <div className="min-height">
        <Title titleName="Format" />
        <Header link="/" titleHeader={t('Format')} linkText={t('Back')} />
          <ButtonLink
            link="/tarif/format"
            linkText={t("Back")}
            className="button-small-back"
          />
        <GetFormFormat userGroup={userGroup} dataProject={dataProject} />
      </div> 
      <Footer />
    </>
  );
}

export default GetFormat;
