import React from 'react'
import Header from '../../components/Header/'
import HomeComponent from '../../components/Home'
import Title from '../../components/Title'
import Footer from '../../components/Footer'
import useT from '../../components/Traduction'

function Home({ userGroup }) {
    const t = useT()
    return (
        <>
            <div className="min-height">
                <Title titleName="Accueil" />
                <Header titleHeader={t("Reference_Catalog")} />
                <HomeComponent userGroup={userGroup} />
            </div>
            <Footer />
        </>
    )
  }

export default Home