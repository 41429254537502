import React from 'react'
import Header from '../../components/Header'
import CreateNewFormat from '../../components/CreateFormat/createFormat'
import Title from '../../components/Title'
import ButtonLink from '../../components/ButtonLink'
import Footer from '../../components/Footer'
import useT from '../../components/Traduction'


function CreateFormat({ userGroup, dataProject }) {
  const t = useT()
    return (
      <>
        <div className="min-height">
          <Title titleName={t('Create_format')} />
          <Header link="/" titleHeader={t('Create_format')} linkText={t('Back')} />
          <ButtonLink link="/tarif/format" 
            linkText={t("Back")} 
            className="button-small-back"
          />
          <CreateNewFormat userGroup={userGroup} dataProject={dataProject} />
        </div>
        <Footer />
      </>

    )
  }
  
export default CreateFormat