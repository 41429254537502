import React from 'react'
import ButtonLink from '../ButtonLink'
import useT from '../../components/Traduction'

function TarifComponent({ userGroup }) {
    const t = useT()

    return (
        <div className="container">
            <div className="home">
                {(userGroup === "super-admin" || userGroup === "admin") ? (
                    <ul className="text-center btn-list">
                        <li className="btn-element">
                            <ButtonLink link="/tarif/dépôt" linkText={t('Deposit_file')} className="button-large" />
                        </li>
                        <li className="btn-element">
                            <ButtonLink link="/tarif/format" linkText={t('format')} className="button-large" />
                        </li>
                        <li className="btn-element">
                            <ButtonLink link="/tarif/supplier/création-fournisseur" linkText={t('Add_supplier')} className="button-large" />
                        </li>
                    </ul>
                ) : null}
            </div>
        </div>
    )    
}

export default TarifComponent
