import React from 'react'
import Header from '../../components/Header'
import Title from '../../components/Title'
import ButtonLink from '../../components/ButtonLink'
import useT from '../../components/Traduction'
import Footer from '../../components/Footer'
import AddSupplier from '../../components/AddSupplier/add-supplier'


function AddSupplierPage({ userGroup, dataProject }) {
    const t = useT()
    return (
        <>
            <div className="min-height">
                <Title titleName={t("Add_supplier")}/>
                <Header link="/" titleHeader={t('Add_supplier')} linkText={t('Back')} />
                <ButtonLink link="/tarif" 
                    linkText={t("Back")} 
                    className="button-small-back"
                />
                <AddSupplier userGroup={userGroup} dataProject={dataProject}/>
            </div> 
            <Footer />
        </>
    )
}

export default AddSupplierPage;
