import React from "react";
import useT  from '../../../Traduction';

const FieldMappingOverview = ({ formatData }) => {
  const t = useT();
  return (
    <div>
      <h4 className="text-center my-5">{t("File_mapping")}</h4>
      <div className="row">
        {Object.entries(formatData.format.fields_mapping).map(([key, value]) => (
          <div className="col-md-4 mb-5" key={key}>
            <strong>{t("Header")}:</strong> {key}
            {Object.entries(value).map(([fieldMappingKey, fieldMappingValue]) => (
              <React.Fragment key={fieldMappingKey}>
                {fieldMappingKey === 'output_field' && (
                  <div>
                    <strong>{t("Output_field")}:</strong> {t(fieldMappingValue)}
                  </div>
                )}
                {fieldMappingKey === 'column_type' && (
                  <div>
                    <strong>{t("Column_type")}:</strong> {t(fieldMappingValue)}
                  </div>
                )}
                {fieldMappingKey === 'is_required' && (
                  <div>
                    <strong>{t("Is_required")}:</strong> {typeof fieldMappingValue === 'boolean' ? (fieldMappingValue ? t("Yes") : t("No")) : t(fieldMappingValue)}
                  </div>
                )}
                {formatData.format.fixed_field_size && fieldMappingKey === 'start_of_length' && (
                  <div>
                    <strong>{t("Start_of_length")}:</strong> {fieldMappingValue}
                  </div>
                )}
                {formatData.format.fixed_field_size && fieldMappingKey === 'end_of_length' && (
                  <div>
                    <strong>{t("End_of_length")}:</strong> {fieldMappingValue}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FieldMappingOverview;
