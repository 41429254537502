import Error from '../../components/Error'
import Footer from '../../components/Footer';
import Title from '../../components/Title'
import useT from '../../components/Traduction'


function Error403() {
    const t = useT()

    return (
        <>
            <div className="min-height">
                <Title titleName={t("Denied_access")} />
                <Error errorCode="403" errorText={t("Denied_access_message")} />
            </div>
            <Footer />
        </>
    )
}  

export default Error403;
