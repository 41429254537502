import React, { useState } from "react";
import useT from '../../Traduction';

const MeasurementUnit = ({ setMeasurementUnitConfirmed, measurementUnitFields, lengthUnit, setLengthUnit, heightUnit, setHeightUnit, volumeUnit, setVolumeUnit, weightUnit, setWeightUnit, widthUnit, setWidthUnit }) => {
  const t = useT();
  const [errorMessage, setErrorMessage] = useState("");
  const handleButtonClick = (event) => {
    event.preventDefault();
    let hasError = false;
    measurementUnitFields.forEach(field => {
        if (field === "length" && lengthUnit === "") {
            hasError = true;
        }
        if (field === "height" && heightUnit === "") {
            hasError = true;
        }
        if (field === "volume" && volumeUnit === "") {
            hasError = true;
        }
        if (field === "weight" && weightUnit === "") {
            hasError = true;
        }
        if (field === "width" && widthUnit === "") {
            hasError = true;
        }
    });

    if (hasError) {
        setErrorMessage(t("You_must_select_a_unit_of_measurement_for_each_item"));
    } else {
        setErrorMessage("");
        setMeasurementUnitConfirmed(true);
    }
  };

  return (
    <form className="form-control text-center shadow p-3 mb-5 bg-body rounded">
      <div className="row mb-1">
        {measurementUnitFields.includes("height") && (
          <div className="col-md-6 mb-3 mt-2 text-center">
            <label className="my-1 fw-bold">{t("Height")} :</label>
            <select
              className="form-control"
              value={heightUnit}
              onChange={(e) => setHeightUnit(e.target.value)}
            >
              <option className="text-center" value="" disabled>{t("Select_a_measurement_unit")}</option>
              <option className="text-center" value="mm">{t("mm")}</option>
              <option className="text-center" value="cm">{t("cm")}</option>
              <option className="text-center" value="m">{t("m")}</option>
            </select>
          </div>
        )}
        {measurementUnitFields.includes("length") && (
          <div className="col-md-6 mb-3 mt-2 text-center">
            <label className="my-1 fw-bold">{t("Length")} :</label>
            <select
              className="form-control"
              value={lengthUnit}
              onChange={(e) => setLengthUnit(e.target.value)}
            >
              <option className="text-center" value="" disabled>{t("Select_a_measurement_unit")}</option>
              <option className="text-center" value="mm">{t("mm")}</option>
              <option className="text-center" value="cm">{t("cm")}</option>
              <option className="text-center" value="m">{t("m")}</option>
            </select>
          </div>
        )}
        {measurementUnitFields.includes("width") && (
          <div className="col-md-6 mb-3 mt-2 text-center">
            <label className="my-1 fw-bold">{t("Width")} :</label>
            <select
              className="form-control"
              value={widthUnit}
              onChange={(e) => setWidthUnit(e.target.value)}
            >
              <option className="text-center" value="" disabled>{t("Select_a_measurement_unit")}</option>
              <option className="text-center" value="mm">{t("mm")}</option>
              <option className="text-center" value="cm">{t("cm")}</option>
              <option className="text-center" value="m">{t("m")}</option>
            </select>
          </div>
        )}
        {measurementUnitFields.includes("weight") && (
          <div className="col-md-6 mb-3 mt-2 text-center">
            <label className="my-1 fw-bold">{t("Weight")} :</label>
            <select
              className="form-control"
              value={weightUnit}
              onChange={(e) => setWeightUnit(e.target.value)}
            >
              <option className="text-center" value="" disabled>{t("Select_a_measurement_unit")}</option>
              <option className="text-center" value="g">{t("g")}</option>
              <option className="text-center" value="kg">{t("kg")}</option>
              <option className="text-center" value="t">{t("t")}</option>
            </select>
          </div>
        )}
        {measurementUnitFields.includes("volume") && (
          <div className="col-md-6 mb-3 mt-2 text-center">
            <label className="my-1 fw-bold">{t("Volume")} :</label>
            <select
              className="form-control"
              value={volumeUnit}
              onChange={(e) => setVolumeUnit(e.target.value)}
            >
              <option className="text-center" value="" disabled>{t("Select_a_measurement_unit")}</option>
              <option className="text-center" value="ml">{t("ml")}</option>
              <option className="text-center" value="cl">{t("cl")}</option>
              <option className="text-center" value="l">{t("l")}</option>
            </select>
          </div>
        )}
        {errorMessage && <div className="d-flex justify-content-center"><div className="alert alert-warning mt-4 fw-bold center">{errorMessage}</div></div>}
        <div className="d-flex justify-content-center my-2">
          <button type="submit" className="btn mt-3" onClick={handleButtonClick}>{t("Validate")}</button>
        </div>
      </div>
    </form>
  );
}

export default MeasurementUnit;