export const exclude_columns = [
    "activity",
    "bar_code",
    "bar_code2",
    "bar_code3",
    "bar_code4",
    "brand",
    "color",
    "conditioning",
    "country_origin",
    "customs_code",
    "description",
    "designation",
    "discount_code",
    "eco_tax",
    "family",
    "height",
    "length",
    "manufacturer_reference",
    "materials",
    "price_sb",
    "purchase_price_et",
    "purchase_price_it",
    "recommended_public_price_et",
    "recommended_public_price_it",
    "ref_type",
    "reference",
    "replacement",
    "selling_price_et",
    "selling_price_it",
    "size",
    "sub_family",
    "sub_family2",
    "sub_family3",
    "supplier",
    "url_photo",
    "url_product_sheet",
    "vat_rate",
    "volume",
    "weight",
    "width",
    "year",
  ];