import { React, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useT from '../Traduction'
import { Link } from 'react-router-dom';
import FranceFlag from '../../assets/images/country_flag_fr.png';
import UnitedKingdomFlag from '../../assets/images/country_flag_gb.png';
import FuturosoftLogo from '../../assets/images/Futurosoft_Logos_Bleu.png';
import { Dropdown } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

const lngs = {
  fr: { 
    icon: <img src={FranceFlag} alt="France flag" />,
    name: "French"
  },
  en: { 
    icon: <img src={UnitedKingdomFlag} alt="United Kingdom flag" />,
    name: "English"
  },
};

function Header({ titleHeader }) {
  const t = useT();
  const { i18n } = useTranslation();
  const [user, setUser] = useState({});

  useEffect(() => {
    const getUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setUser(currentUser);
      } catch (error) {}
    };
    getUser();
  }, []);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      window.location.replace('/connexion');
    } catch (error) {}
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link to="/" className="nav-link mx-2">
          <img src={FuturosoftLogo} height="30" alt="Futurosoft Logo" />
        </Link>

        <div className="collapse navbar-collapse" id="navbarButtonsExample">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/" className="nav-link">{t("Home")}</Link>
            </li>
          </ul>
          <div>
          {user.signInUserSession && (
            <div>
              <span className="mx-4">{user.attributes.email}</span>
              <button className="btn btn-outline-danger btn-sm" onClick={handleSignOut}>{t("Sign_out")}</button>
            </div>
          )}
          </div>
          <div className="d-flex align-items-center mx-2">
          <Dropdown>
            <Dropdown.Toggle variant="light" id="language-dropdown">
              {i18n.language.startsWith('fr') ? lngs['fr'].icon : lngs['en'].icon}
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                minWidth: i18n.language.startsWith('fr')
                  ? lngs['fr'].icon.width + 20
                  : lngs['en'].icon.width + 20,
              }}
            >
              {Object.keys(lngs).map((lng) => (
                <Dropdown.Item
                  key={lng}
                  onClick={() => i18n.changeLanguage(lng)}
                  disabled={i18n.resolvedLanguage === lng}
                >
                  <span className="mx-2 text-center">
                    {lng === 'fr' ? lngs['fr'].icon : lngs['en'].icon}
                  </span>
                  <span>{t(lngs[lng].name)}</span>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          </div>
        </div>
      </nav>

      <h1 className="text-center title-header m-4">{titleHeader}</h1>
    </header>
  );
}

export default Header;
