import Header from '../../components/Header';
import DepositTrackingComponent from '../../components/DepositTracking'
import Title from '../../components/Title';
import ButtonLink from '../../components/ButtonLink';
import useT from '../../components/Traduction'
import Footer from '../../components/Footer';
import React from 'react';

function DepositTracking({ userGroup, dataProject}) {
  const t = useT();

  return (
    <>
      <div className="min-height">
        <Title titleName="Suivi de dépôt" />
        <Header link="/" titleHeader={t('Deposit_tracking')} linkText={t('Back')} />
        <ButtonLink link="/tarif/dépôt" linkText={t("Back")} className="button-small-back" />
        <div className="text-center m-5">
          <DepositTrackingComponent userGroup={userGroup} dataProject={dataProject}/>
        </div>
      </div> 
      <Footer />
    </>
  );
}

export default DepositTracking;